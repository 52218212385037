
        <img class="d-block w-100" src="../../../assets/edicion/sistemas.png" >
     
        <br>
        <div class="embed-responsive embed-responsive-21by9"> 
          <youtube-player 
          videoId="M9Mg2Asdjw8" 
          [height]="500" 
          [width]="1000" >
        </youtube-player>
    </div>
<br>  
     
  <section class="section-35 section-md-50">
    <div class="container">
      <h4 style="text-align: center;">¿Te apasiona la tecnología? ¿Estás interesadoen crear
        aplicaciones móviles? ¿Te gustaría desarrollar sistemas en la
        nube? ¿Quieres aprender a configurar redes virtuales?</h4>
      <br>
     <p class="inset-lg-left-40 inset-xl-left-70 text-secondary"> Estudia Ingeniería en Sistemas y Desarrollo de Software en el
      Instituto Sanmiguelense, e innova a través de tus ideas, con
      los mejores docentes capacitados en el ámbito de la
      programación, inteligencia artificial, internet de las cosas
      y toda la industria 4.0. </p>
    
     <br>
      <div class="row row-30 flex-row-md-reverse justify-content-lg-between">
        <div class="col-md-6">
          <figure><img src="../../../assets/edicion/general/9.png" alt="" width="570" height="386"/>
          </figure>
        </div>
        <div class="col-md-6">
          <div class="inset-lg-right-40 inset-xl-right-85 text-secondary">
            <p>Estudia una de las carreras más demandadas por la industria y
              aprende a construir el mundo de mañana y moldea la realidad
              virtual en la que vivimos, construye las plataformas
              digitales que facilitarán la vida de las personas, conecta al
              mundo a través del desarrollo de redes sociales, crea los
              drones y robots que mejorarán las condiciones de trabajo en
              las empresas.</p>
       <p>Gracias a la gran vinculación del Instituto Sanmiguelense
        también podrás obtener cursos y capacitación gratuita, además
        de certificarte en las mejores academias como Oracle, CISCO y
        Huawei y obtén una ventaja competitiva para el mundo laboral
        antes de graduarte. Como Ingeniero en Sistemas y Desarrollo de Software podrás
        desenvolverte en industrias como:
        Automotriz, aeronáutica, aeroespacial, electrodomésticos, energía, telecomunicación, digitalización y seguridad</p>
     </div>
        </div>
      </div>
    </div>
  </section>
  <h4 style="text-align: center;">Vinculación Empresarial</h4>
  <img class="d-block w-100" src="../../../assets/edicion/varioslogos/2.png" >
  <section class="section-40 section-md-bottom-100 section-xl-bottom-165">
    <div class="container">
      <h4>Algunas de las áreas donde nuestros egresados se encuentran trabajando son:</h4>
      <div class="row row-30 justify-content-lg-between">
        <div class="col-md-6">
          <figure><img src="../../assets/edicion/general/3.png" alt="" width="570" height="386"/>
          </figure>
        </div>
        <div class="col-md-6">
          <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
            <p>Redes y telecomunicación.</p>
            <p>Desarrollo de Software.</p>        
            <p>Mantenimiento y Soporte.</p>
            <p>Big Data.</p>
            <p>Inteligencia Artificial.</p>
            <p>Desarrollo de Aplicaciones Móviles.</p>
            <p>Blockchain.</p>
            <p>Entre otras.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="container">

    <div class="row row-30 justify-content-lg-between">
      <div class="col-md-4">
       
        <button class="btn btn-info" (click)="open(mymodal1)"><img src="../../assets/edicion/btn1.png" alt=""></button>
   
        <ng-template #mymodal1 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
            <img class="d-block w-100" src="../../assets/edicion/soydragon/8.png" alt="" width="100%" height="auto">
          </div>
     
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>
      <div class="col-md-4">
        <button class="btn btn-info" (click)="open(mymodal2)"><img src="../../assets/edicion/btn2.png" alt=""></button>
   
        <ng-template #mymodal2 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
            <img class="d-block w-100" src="../../assets/edicion/soydragon/9.png" alt="">
          </div>
          
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>
      <div class="col-md-4">
        <button class="btn btn-info" (click)="open(mymodal)"><img src="../../assets/edicion/btn3.png" alt=""></button>
   
        <ng-template #mymodal let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
            <img src="../../assets/edicion/reticulasis.png" alt="" width="3000" height="auto"> 
          </div>
          
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <br>