
        <img class="d-block w-100" src="../../../assets/edicion/nosotros.png" >
     <br>
<section class="bg-displaced-wrap">
    <div class="bg-displaced-body">
      <div class="container">
        <div class="inset-xl-left-70 inset-xl-right-70">
          <article class="box-cart bg-ebony-clay">
            <div class="box-cart-image"><img src="../../../assets/edicion/fer3.png" alt="" width="342" height="338"/>
            </div>
            <div class="box-cart-body">
              <blockquote class="blockquote-complex blockquote-complex-inverse">
                <h3>Conócenos</h3>
                <p>
                  <q>En el Instituto Sanmiguelense nada nos detiene.  Con el apoyo de todos nuestros Profesores y Alumnos estamos Innovando la Educación.</q>
                </p>
                <div class="quote-footer">
                  <cite>Fernando Balderas</cite><small>Presidente Instituto Sanmiguelense</small>
                </div>
              </blockquote>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="bg-displaced bg-image" style="background-image: url(assets/images/home-1.jpg);"></div>
  </section>




<br>

<div class="container">
  <h3 style="text-align: center;">Historia</h3>
  <div class="row row-30 justify-content-lg-between">
    <div class="col-md-6">
      <figure><img src="../../../assets/edicion/general/1.png" alt="" width="570" height="386"/>
      </figure>
    </div>
    <div class="col-md-6">
      <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
    <p>El Instituto Sanmiguelense es una nueva forma de institución de enseñanza media superior y superior, que surge desde la industria, dónde se gesta el nuevo conocimiento, y nuevas realidades a las cuales se les debe hacer frente con programas educativos pertinentes y a la vanguardia.</p> 
  <p>En el año 2004, gracias a la visión del Ing. Fernando Balderas López, socios y colaboradores de la empresa Grupo SSC es que surge el Instituto Sanmiguelense con la visión de aportar a la sociedad: Educación de calidad a nivel profesional en programas académicos de vanguardia para los jóvenes sanmiguelenses.</p>
  </div>
    </div>
  </div>
</div>



  <div class="container">
    <h3 style="text-align: center;">Filosofia</h3>
    <div class="row row-30 justify-content-lg-between">
      <div class="col-md-6">
        <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
      <p>
        En el Instituto Sanmiguelense creemos firmemente que el desarrollo de una sociedad reside en una educación integral, incluyente y accesible.
      </p> 
    <p>Que siempre esté a la vanguardia a través de la innovación y desarrollo tecnológico, sin olvidar el desarrollo humano. </p>
  <p>Es importante para nosotros crear una vinculación directa entre todos los actores productivos que incidan en una educación pertinente a lo que éstos requieren, con el fin último de aportar el conocimiento de manera solidaria a la comunidad. </p> 
  </div>

      </div>
      <div class="col-md-6">
     
    <figure><img src="../../../assets/edicion/general/2.png" alt="" width="570" height="386"/>
    </figure>
      </div>
    </div>
  </div>
<br>

<div class="container">
  <h3 style="text-align: center;">Misión</h3>
  <div class="row row-30 justify-content-lg-between">
    <div class="col-md-6">
      <figure><img src="../../../assets/edicion/general/6.png" alt="" width="570" height="386"/>
      </figure>
    </div>
    <div class="col-md-6">
      <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
 <p>El Instituto Sanmiguelense busca contribuir a una sociedad global donde predomine la verdad, la justicia y la libertad, formando personas líderes, solidarias e íntegras, a través de una educación de excelencia, con mentalidad internacional, siempre a la vanguardia, desarrollando y compartiendo el conocimiento, habilidades y competencias de manera integral, priorizando el desarrollo humano, innovación, tecnología, negocios, investigación y vinculación con los actores productivos del sector público y privado.</p>
      </div>
    </div>
  </div>
</div>



  <div class="container">
    <h3 style="text-align: center;">Visión</h3>
    <div class="row row-30 justify-content-lg-between">
      <div class="col-md-6">
        <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
<p>El Instituto Sanmiguelense estará entre las 10 instituciones educativas de mayor prestigio e influencia en el Bajío en su desarrollo económico y humano. 

  Sus programas académicos serán acreditados y reconocidos por su calidad y excelencia. 
  
  </p>
  <p>Formaremos personas líderes, competitivas y solidarias, que aporten al crecimiento y proyección de la economía basada en conocimiento a nivel nacional e internacional, apoyados en programas de intercambios internacionales, insertándose en los sectores productivos públicos y privados, generando valor basado en la innovación y desarrollo tecnológico para el desarrollo sostenible y más equitativo de la sociedad.</p>
        </div>

      </div>
      <div class="col-md-6">
     
    <figure><img src="../../../assets/edicion/general/11.png" alt="" width="570" height="386"/>
    </figure>
      </div>
    </div>
  </div>
<br>