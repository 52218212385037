import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'institutopage';
  audioList = [
    {
      url: "https://sp3.servidorrprivado.com/8068/stream",
      title: "Dragon Estereo",
      cover: "https://dragonestereo.com/wp-content/uploads/2021/03/cropped-DRAGON-CON-AUDIFONOS-V2-192x192.png"
    }
  ];
}
