import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { InicioComponent } from './inicio/inicio.component';
import { BecasComponent } from './becas/becas.component';
import { MecanicaComponent } from './mecanica/mecanica.component';
import { SistemasComponent } from './sistemas/sistemas.component';
import { DisenoComponent } from './diseno/diseno.component';
import { GestionComponent } from './gestion/gestion.component';
import { OrgulloDragonComponent } from './orgullo-dragon/orgullo-dragon.component';
import { InstalacionesComponent } from './instalaciones/instalaciones.component';
import { VinculacionComponent } from './vinculacion/vinculacion.component';
import { ConocenosComponent } from './conocenos/conocenos.component';
import { AppdragonComponent } from './appdragon/appdragon.component';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { AdmisionesComponent } from './admisiones/admisiones.component';
import { NavbarComponent} from './navbar/navbar.component';
import { ImageHttpPipe } from './image-http.pipe';

import { AngMusicPlayerModule } from  'ang-music-player';

const routes: Routes = [
  { path: '', component: InicioComponent },
   { path: 'inicio', component: InicioComponent },
   { path: 'admisiones/:cod', component: AdmisionesComponent },
   { path: 'becas', component: BecasComponent },
   { path: 'conocenos', component: ConocenosComponent },
   { path: 'diseno', component: DisenoComponent },
   { path: 'gestion', component: GestionComponent},
   { path: 'instalaciones', component: InstalacionesComponent},
   { path: 'mecanica', component: MecanicaComponent},
   { path: 'orgullodragon', component: OrgulloDragonComponent},
   { path: 'sistemas', component: SistemasComponent},
   { path: 'vinculacion', component: VinculacionComponent},
   { path: 'appdragon', component: AppdragonComponent},
   { path: '**', pathMatch: 'full', redirectTo: ''}
 
 ];
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    InicioComponent,
    BecasComponent,
    MecanicaComponent,
    SistemasComponent,
    DisenoComponent,
    GestionComponent,
    OrgulloDragonComponent,
    InstalacionesComponent,
    VinculacionComponent,
    ConocenosComponent,
    AppdragonComponent,
    AdmisionesComponent,
    ImageHttpPipe
  ],
  imports: [
    BrowserModule,
    AngMusicPlayerModule,
    ToastrModule.forRoot(),
    HttpModule,
    FormsModule,
    RouterModule.forRoot(routes, { useHash: true }),
    HttpClientModule,
    YouTubePlayerModule,
    NgxGalleryModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
