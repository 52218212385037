import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'

import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'flatpickr/dist/flatpickr.css';
import {
    startOfDay,
    endOfDay,
    subDays,
    addDays,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours
} from 'date-fns';
import { Subject } from 'rxjs';
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarView
} from 'angular-calendar';
const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3'
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF'
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA'
    }
};

@Component({
    selector: 'app-admisiones',
    templateUrl: './admisiones.component.html',
    styleUrls: ['./admisiones.component.scss']
})

export class AdmisionesComponent implements OnInit {
    Swal: 'sweetalert2';
    Formulario: FormGroup;
    submitted = false;
    data: any;
    name: any;
    email: any;
    programa: any;
    fb: any;
    escuela: any;
    tel: any;
    cod = '';
    date1: '2019-02-20T00:00:00';
    @ViewChild('mod') mod: ElementRef;
    @ViewChild('auto') auto: ElementRef;
    @ViewChild('modalContent')
    modalContent: TemplateRef<any>;

    view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;

    viewDate: Date = new Date();

    modalData: {
        action: string;
        event: CalendarEvent;
    };
    /*actions: CalendarEventAction[] = [
        {
            label: '<i class="fa fa-fw fa-pencil"></i>',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event);
            }
        },
        {
            label: '<i class="fa fa-fw fa-times"></i>',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events = this.events.filter(iEvent => iEvent !== event);
                this.handleEvent('Deleted', event);
            }
        }
    ];*/

    refresh: Subject<any> = new Subject();

    events: CalendarEvent[] = [
        {
            start: new Date('January 17, 2019 '),
            end: new Date('January 18, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('January 31, 2019 '),
            end: new Date('January 31, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('January 24, 2019 '),
            end: new Date('January 25, 2019 '),
            title: 'Exámenes de Prepararoria ',
            color: colors.blue,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('February 1, 2019 '),
            end: new Date('February 1, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('February 14, 2019 '),
            end: new Date('February 15, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('February 28, 2019 '),
            end: new Date('February 28, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('February 28, 2019 '),
            end: new Date('February 28, 2019 '),
            title: 'Exámenes de Preparatoria ',
            color: colors.blue,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        }
        ,
        {
            start: new Date('March 1, 2019 '),
            end: new Date('March 1, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('March 14, 2019 '),
            end: new Date('March 15, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('March 28, 2019 '),
            end: new Date('March 29, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('March 28, 2019 '),
            end: new Date('March 29, 2019 '),
            title: 'Exámenes de Preparatoria ',
            color: colors.blue,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('April 11, 2019 '),
            end: new Date('April 12, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('April 25, 2019 '),
            end: new Date('April 26, 2019 '),
            title: 'Exámenes de Preparatoria ',
            color: colors.blue,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('May 2, 2019 '),
            end: new Date('May 3, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('May 16, 2019 '),
            end: new Date('May 17, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('May 30, 2019 '),
            end: new Date('May 31, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('May 30, 2019 '),
            end: new Date('May 31, 2019 '),
            title: 'Exámenes de Preparatoria ',
            color: colors.blue,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('Jun 13, 2019 '),
            end: new Date('Jun 14, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('Jun 27, 2019 '),
            end: new Date('Jun 28, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('Jun 27, 2019 '),
            end: new Date('Jun 28, 2019 '),
            title: 'Exámenes de Preparatoria ',
            color: colors.blue,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('July 11, 2019 '),
            end: new Date('July 12, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('July 25, 2019 '),
            end: new Date('July 26, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('July 25, 2019 '),
            end: new Date('July 26, 2019 '),
            title: 'Exámenes de Preparatoria ',
            color: colors.blue,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('Agust 8, 2019 '),
            end: new Date('Agust 9, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('Agust 22, 2019 '),
            end: new Date('Agust 23, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
        {
            start: new Date('September 4, 2019 '),
            end: new Date('September 5, 2019 '),
            title: 'Exámenes de Licenciatura ',
            color: colors.red,
            /*actions: this.actions,*/
            allDay: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            },
            draggable: true
        },
    ];
    activeDayIsOpen: any;
    IndexEstados = [];
    Estado = '';
    IndexEscuela = [];
    indexNivelAcademico = [];
    constructor(private route: ActivatedRoute, public fbu: FormBuilder,
        private router: Router, private http: HttpClient,
         private toastr: ToastrService, private modal: NgbModal) {
        this.route.params.subscribe(params => {
            this.cod = params['cod'];
            this.createForm(this.cod);
        });
        this.http.get('http://itses.edu.mx/ws-sii-v2/controllers/' +
        'escuela_procedencia/_api.php?opcion=indexEstado').subscribe((data: any) => {
               this.IndexEstados = data;
           });
        this.http.get('http://itses.edu.mx/ws-sii-v2/controllers/' +
        'escuela_procedencia/_api.php?opcion=indexNivelAcademico').subscribe((data: any) => {
               this.indexNivelAcademico = data;
               console.log(this.indexNivelAcademico);
           });
            }
    ObtenerEscuelas(ID) {
        this.http.post('http://itses.edu.mx/ws-sii-v2/controllers/' +
        'escuela_procedencia/_api.php?opcion=getList', {id: ID}).subscribe((data: any) => {
           console.log(data);
           this.IndexEscuela = data;
        });
    }

    createForm(CODIGO) {
        this.Formulario = this.fbu.group({
          nombre: ['', [Validators.required]],
          apell_p: ['', [Validators.required]],
          apell_m: [''],
          correo: ['', [Validators.required, Validators.email]],
          estado: ['', [Validators.required]],
          tbl_nivel: ['', [Validators.required]],
          telefono: ['', [Validators.required, Validators.maxLength(15), Validators.minLength(10)]],
          escuela: ['', [Validators.required]],
          codigo: [CODIGO, [Validators.required]]
        });
      }
    mail2() {
        if (this.name == null || this.email == null || this.escuela == null ||
             this.tel == null || this.programa == null || this.fb == null) {
            this.toastr.error('Por Favor Llena Todos Los Campos!');
        } else {
            const el: HTMLElement = this.mod.nativeElement as HTMLElement;
            el.click();
            this.http.get('http://35.209.216.230/smtpServer/email.php?email="' + this.email + '" &name="' +
             this.name + '" &tel="' + this.tel + '" &escuela="' + this.escuela + '" &fb="' +
              this.fb + '" &programa="' + this.programa + '"').subscribe(
                (data: any) => {
                    console.log(data);
                    this.data = data;
                });
            this.toastr.info('Listo!');
        }
    }
    onEdit() { /*funcion para ir hacia arriba */
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    ngOnInit() { /* para ir hasta arriba de una pagina */
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
        // this.modal2();
    }
    modal2() {
        const ver: HTMLElement = this.auto.nativeElement as HTMLElement;
            ver.click();
        if (window.screen.width >= 768) { 
        }

    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
        }
    }

    eventTimesChanged({
        event,
        newStart,
        newEnd
    }: CalendarEventTimesChangedEvent): void {
        event.start = newStart;
        event.end = newEnd;
        this.handleEvent('Dropped or resized', event);
        this.refresh.next();
    }

    handleEvent(action: string, event: CalendarEvent): void {
        this.modalData = { event, action };
        this.modal.open(this.modalContent, { size: 'lg' });
    }

    addEvent(): void {
        this.events.push({
            title: 'New event',
            start: startOfDay(new Date()),
            end: endOfDay(new Date()),
            color: colors.red,
            draggable: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            }
        });
        this.refresh.next();
    }


  get f() { return this.Formulario.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.Formulario.invalid) {
      return;
    }
    this.http.post('https://itses.edu.mx/accionPagina.php', this.Formulario.value).subscribe((data: any) => {
       // this.toastr.info('Listo!, Favor de revisar su correo');
        Swal.fire(
            'Listo!',
            'Favor de revisar su correo!',
            'success'
          )
        this.onReset();
        });
  }

  onReset() {
    this.submitted = false;
    this.Formulario.reset();
  }
}
