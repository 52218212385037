
        <img class="d-block w-100" src="../../../assets/edicion/instalaciones.png" >
     <br>
     <p class="inset-lg-left-40 inset-xl-left-70 text-secondary">En el Instituto Sanmiguelense podrás encontrar las instalaciones que requieres para tu desarrollo integral, instalaciones deportivas, talleres para realizar actividades culturales, salas de cómputo con los mejores softwares utilizados por la industria actualmente, herramientas tecnológicas y de vanguardia, todo esto rodeado de naturaleza y espacios para convivir con tus compañeros.</p>
     <br>
     <div class="embed-responsive embed-responsive-21by9"> 
        <youtube-player 
        videoId="iRzLTdurDrs" 
        [height]="500" 
        [width]="1000" >
      </youtube-player>
  </div>

 

  <p class="wow fadeInUp t" >Las mejores instalaciones en San Miguel de Allende</p>
  <div class="ngx-gallery">
    <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
  </div>