<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>

    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="../../../assets/edicion/2.png" alt="First slide">
      </div>

      <div class="carousel-item">
        <img class="d-block w-100" src="../../../assets/edicion/3.png" alt="Two slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="../../../assets/edicion/4.png" alt="Third slide">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>




  <div class="container-fluid esp1 mac" >
    <p class="wow fadeInUp t" >Los mejores alumnos en las mejores empresas</p>
</div>
<div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
  <!--
    <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators2" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="3"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="4"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="5"></li>
  </ol>
-->
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/3.png" alt="" width="246" height="300"/>
              <br>
             
            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
            <figure>
              <h5> El nivel académico de los alumnos del Sanmiguelense se encuentra encima del de otras universidades en Querétaro y Guanajuato. Su nivel de inglés es excelente y un plus para su ingreso a nuestra empresa, lo que resulta una ventaja competitiva frente a otros candidatos.   </h5>
              <div class="quote-footer">
                <cite>- Jessica Estañol. HR Specialist Huawei Technologies de México. GSC Querétaro.</cite>
                <br>
              </div>
            </figure>
          </div>
        </div>
  
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/4.png" alt="" width="246" height="300"/>
              <br>
             
            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
            <figure>
              <h5> Los alumnos del Sanmiguelense de Diseño y Comunicación, así como de Gestión Empresarial, han cumplido con las expectativas de nuestra empresa. Su preparación académica es excelente para lo que demanda el mercado laboral.  </h5>
              <div class="quote-footer">
                <cite>- Joana Rodríguez. Administradora Kalôn & Agathos Bajío.</cite>
                <br>
              </div>
            </figure>
          </div>
        </div>
  
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/2.png" alt="" width="246" height="300"/>
              <br>
             
            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
            <figure>
              <h5> Los alumnos del Sanmiguelense que han participado en nuestro evento Aggies Invent lo han hecho de manera sobresaliente y se encuentran al nivel de cualquier universidad del mundo. El enfoque tecnológico y de innovación es asombroso.
              </h5>
              <div class="quote-footer">
                <cite>- Pablo Marvin. Director del Study Abroad Program de Hacienda Santa Clara y representante de la universidad de Texas A&M en México.</cite>
                <br>
              </div>
            </figure>
          </div>
        </div>
  
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/6.png" alt="" width="246" height="300"/>
              <br>
             
            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
            <figure>
              <h5> Dada la formación que reciben los estudiantes en el ITSES en áreas de ingeniería mecánica con un componente importante de simulación computacional, son candidatos idóneos para la maestría así como para algún tipo de proyectos que aquí se desarrollan.   </h5>
              <div class="quote-footer">
                <cite>- Carlos A. Escalante V.
                  Investigador CONACYT-CIDESI (CONACYT Research Fellow)</cite>
                <br>
              </div>
            </figure>
          </div>
        </div>
  
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/5.png" alt="" width="246" height="300"/>
              <br>
             
            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
            <figure>
              <h5> El Instituto Sanmiguelense es una de las principales universidades que nos ayuda a reunir talentos para cubrir las necesidades de Valeo, en especial para nuestro Centro Técnico en México (MTC) debido a su alta especialización.  </h5>
              <div class="quote-footer">
                <cite>- Vanessa Fonseca, Country Talent Acquisition Manager - Mèxico</cite>
                <br>
              </div>
            </figure>
          </div>
        </div>
  
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/1.png" alt="" width="246" height="300"/>
              <br>
             
            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
            <figure>
              <h5> Estuve a punto de ingresar a otra institución, sin embargo, cuando consulté el plan de estudio me di cuenta que lo que iba a ver de diseño y simulación ya lo había aprendido en la Prepa Sanmiguelense, y en el Instituto Sanmiguelense cuentan con un programa más avanzado y profesores más capacitados. En resumen, son los expertos en diseño y simulación por computadora.
              </h5>
              <div class="quote-footer">
                <cite>- Mario Kilian. Alumno de Ingeniería en Sistemas y Desarrollo de Software.</cite>
                <br>
              </div>
            </figure>
          </div>
        </div>
  
        </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
  <br>
  <p class="wow fadeInUp t" >Responsabilidad Histórica de Ser Vanguardia</p>
<br>
    <div class="container">

      <div class="row row-30 justify-content-lg-between">
        <div class="col-md-4">
          <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
            <br>
            <p>Hoy en día el Instituto Sanmiguelense asume la responsabilidad histórica de ser vanguardia por medio de su modelo educativo que hace énfasis en la formación integral del alumno por medio de innovación, la tecnología, la vinculación, liderazgo y compromiso social.</p>
            <br>
            <button type="button" routerLink="/conocenos" style="margin-left: 30%" class="boton" mdbBtn  mdbWavesEffect>
              Ver Más....
          </button>
          </div>
        </div>
        <div class="col-md-8 embed-responsive embed-responsive-16by9">
          <figure>

            <youtube-player 
  videoId="c1f2q0kR4m4" 
  
  [height]="380" 
  [width]="760" >
</youtube-player>
          </figure>
        </div>

      </div>
    </div>
    <br>
    <p class="wow fadeInUp t" >Vida estudiantil</p>
<div class="ngx-gallery">
  <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
</div>

 

   
     <!-- Titulares de hoy -->

  <div class="container-fluid esp1 mac" >
    <p class="wow fadeInUp t" >Titulares de Hoy</p>
    <p class="txt2 wow fadeInUp" style="text-align: center">Infórmate de todo lo que está pasando dentro y fuera del Instituto Sanmiguelense</p>
</div>

<div class="container-fluid wow pulse esp1 " data-wow-delay="0.8s" >
    <div class="row"  >
        <div class="col-md-6 col-xs-3 col-lg-3" *ngFor="let n of not">
            <!-- Card -->
            <mdb-card style=" margin-bottom: 20%;">
                <div class="view rgba-white-slight waves-light" mdbWavesEffect (click)="new(n.id,n.portada,n.contenido)"  data-toggle="modal" data-target="#basicExampleModal">
                    <!-- Card img -->
                    <img src="{{n.portada| imageHttp}}" class="img-fluid portada po" >
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body >
                    <!--Title-->
                    <mdb-card-title >
                        <div class="row" style="padding-left: 5%;">
                            <!--<button (click)="show(this.urlshare,n.id)"></button>-->
                            <fb-like url="{{this.urlshare+n.id}}" > </fb-like> &nbsp;&nbsp;
                            <tweet url="{{this.urlshare+n.id}}" style="padding-top: 5px"></tweet> &nbsp;&nbsp;
                            <linkedin-share url="{{this.urlshare+n.id}}"></linkedin-share>
                        </div>

                        <p class="txtNot" style="background-color: #030B42;color: white;margin-top: 10px"> &nbsp; {{n.titulo}}</p>
                    </mdb-card-title>
                    <hr>
                    <!--Text-->
                    <mdb-card-text >
                        <!-- Button trigger modal -->
                        <button type="button" style="width: 100%;text-align: center" (click)="new(n.id,n.portada,n.contenido)" class="btn btn-light esconder" data-toggle="modal" data-target="#basicExampleModal">
                            Saber Más...
                        </button>

                    </mdb-card-text>
                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>
        <!-- Modal -->
        <div class="modal fade" id="basicExampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true" style="overflow:scroll!important;">
            <div class="modal-dialog modal-fluid" role="document" >
                <div class="modal-content" >
                    <div class="modal-header">
                        <img src="{{imagen| imageHttp}}" alt="" style="margin-bottom: 50px" class="img-fluid cen" >
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body even cen" style="padding-right: 20%;padding-left: 20%">
                        <p class="txt" [innerHtml]="contenido"></p>
                    </div>
                    <button type="button" class="btn btn-link" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<a href="https://www.preparatemx.com/financiamiento/" target="_blank"><img class="d-block w-100" src="../../../assets/edicion/financiamiento.png" ></a>

<br>