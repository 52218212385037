
        <img class="d-block w-100" src="../../../assets/edicion/mecanica.png" >
     
        <br>
        <div class="embed-responsive embed-responsive-21by9"> 
          <youtube-player 
          videoId="PiolexQhg88" 
          [height]="500" 
          [width]="1000" >
        </youtube-player>
    </div>
<br>  
     
  <section class="section-35 section-md-50">
    <div class="container">
      <h4 style="text-align: center;">  ¿Eres innovador? ¿Te gustaría crear los vehículos del futuro? ¿Te interesa generar mecanismos de energías limpias? ¿Quieres ayudar a crear el mundo del mañana?</h4>
      <br>
     <p class="inset-lg-left-40 inset-xl-left-70 text-secondary">  
      Todo esto lo puedes lograr estudiando Ingeniería Mecánica con Orientación en Diseño y Simulación por Computadora, lo que te dará una ventaja competitiva, pues tendrás acceso a desarrollar tus estudios acompañado por los softwares más poderosos a nivel mundial, estarás preparado para lo que demanda la industria.
     </p>
    
     <br>
      <div class="row row-30 flex-row-md-reverse justify-content-lg-between">
        <div class="col-md-6">
          <figure><img src="../../../assets/edicion/general/5.png" alt="" width="570" height="386"/>
          </figure>
        </div>
        <div class="col-md-6">
          <div class="inset-lg-right-40 inset-xl-right-85 text-secondary">
          
<p>
 

  El enfoque de la carrera es altamente tecnológico, está en constante evolución de acuerdo a lo que requiere la industria, en esta nueva revolución industrial se implementan tecnologías de la industria 4.0 como lo son la simulación y el diseño por computadora con el uso de softwares como ANSYS y CATIA.
</p>
<p>   En el Instituto Sanmiguelense tendrás la posibilidad de tener profesores altamente calificados con vasta experiencia en proyectos reales, lo que hace que más del 90% de nuestros alumnos los califiquen como excelentes.

  Como Ingeniero Mecánico con Orientación en Diseño por Computadora podrás desenvolverte en industrias como:
  Automotriz, aeronáutica, aeroespacial, herramentales, electrodomésticos, energía e investigación
  
   </p>     

     </div>
        </div>
      </div>
    </div>
  </section>
  <h4 style="text-align: center;">Vinculación Empresarial</h4>

  <img class="d-block w-100" src="../../../assets/edicion/varioslogos/1.png" >
  <section class="section-40 section-md-bottom-100 section-xl-bottom-165">
    <div class="container">
      <h4>Algunas de las áreas donde nuestros egresados se encuentran trabajando son:</h4>
     
      <div class="row row-30 justify-content-lg-between">
        <div class="col-md-6">
          <figure><img src="../../assets/edicion/general/10.png" alt="" width="570" height="386"/>
          </figure>
        </div>
        <div class="col-md-6">
          <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">

            <p>Diseño o simulación.</p>
            <p>Desarrollo de Proyectos.</p>
            <p>Calidad.</p>
            <p>Innovación.</p>
            <p>Energías Renovables.</p>
            <p>Óptica.</p>

            <p>Entre otros.</p>
        
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="container">

    <div class="row row-30 justify-content-lg-between">
      <div class="col-md-4">
       
        <button class="btn btn-info" (click)="open(mymodal1)"><img src="../../assets/edicion/btn1.png" alt=""></button>
   
        <ng-template #mymodal1 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
            <img class="d-block w-100" src="../../assets/edicion/soydragon/5.png" alt="" width="100%" height="auto">
          </div>
  
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>
      <div class="col-md-4">
        <button class="btn btn-info" (click)="open(mymodal2)"><img src="../../assets/edicion/btn2.png" alt=""></button>
   
        <ng-template #mymodal2 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
            <img class="d-block w-100" src="../../assets/edicion/soydragon/6.png" alt="">
          </div>
       
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>
      <div class="col-md-4">
        <button class="btn btn-info" (click)="open(mymodal3)"><img src="../../assets/edicion/btn3.png" alt=""></button>
   
        <ng-template #mymodal3 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
            <img src="../../assets/edicion/reticulamec.png" > 
          </div>
          
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <br>