
        <img class="d-block w-100" src="../../../assets/edicion/orgullo.png" >
    <br>
            <div class="container">
              <div class="row row-40 align-items-sm-end">
                <div class="col-sm-6 col-md-4 col-lg-3" *ngFor="let i of orgullodragon">
                  <div class="thumbnail-variant-2-wrap">
                    <div >
                      <figure class="thumbnail-image"><img src="{{i| imageHttp}}" alt="" width="246" height="300"/>
                      </figure>
                      
                      
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
         