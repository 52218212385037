<header class="section header-area" class="page-head">
  <div id="appo-header" class="main-header-area navbar-sticky">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" href="#">
          <img src="assets/logo2.png" width="130" height="auto">
        </a>
        <button type="button" data-toggle="collapse" data-target="#appo-menu" class="navbar-toggler"
          aria-expanded="true"><span class="navbar-toggler-icon"></span></button>
        <div id="appo-menu" class="navbar-collapse collapse">
          <ul class="navbar-nav header-items ml-auto justify-content-end">
            <li class="nav-item active dropdown">
              <a id="navbarDropdown" role="button" data-toggle="dropdown" class="nav-link dropdown-toggle">Nosotros </a>
              <div class="dropdown-menu mega-menu px-3 px-md-4 py-md-4">
                <ul class="single-menu">
                  <li><a class="dropdown-item" routerLink="conocenos">Conócenos</a></li>
                  <li><a routerLink="orgullodragon" class="dropdown-item">Orgullo Dragón</a></li>
                  <li><a routerLink="instalaciones" class="dropdown-item">Instalaciones</a></li>
                </ul>
              </div>
            </li>
            <li class="nav-item active dropdown"><a id="navbarDropdown" role="button" data-toggle="dropdown"
                class="nav-link dropdown-toggle">Oferta Educativa</a>
              <div class="dropdown-menu mega-menu px-3 px-md-4 py-md-4">
                <ul class="single-menu">
                  <li><a class="dropdown-item" routerLink="mecanica">Ingeniería Mecánica</a></li>
                  <li><a class="dropdown-item" routerLink="sistemas">Ingeniería en Sistemas</a></li>
                  <li><a class="dropdown-item" routerLink="gestion">Gestión Empresarial</a></li>
                  <li><a class="dropdown-item" routerLink="diseno">Diseño y Comunicación Visual</a></li>
                </ul>
              </div>
            </li>
            <li class="nav-item"><a class="nav-link scroll" href="https://prepasanmiguelense.mx/"
                target="_blank">Prepa</a></li>
            <li class="nav-item"><a class="nav-link scroll" routerLink="becas">Becas</a></li>
            <li class="nav-item"><a class="nav-link scroll" routerLink="vinculacion"> Vinculación</a></li>
            <li class="nav-item"><a class="nav-link scroll" routerLink="appdragon">Soy Dragón</a></li>
            <!-- 
                   <li class="nav-item"><form >
                    <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Admisiones</button>
                  </form>
                </li> -->
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
<hr>