
        <img class="d-block w-100" src="../../../assets/edicion/diseno.png" >
     
        <br>
        <div class="embed-responsive embed-responsive-21by9"> 
          <youtube-player 
          videoId="gmHv8yhkwkI" 
          [height]="500" 
          [width]="1000" >
        </youtube-player>
    </div>
<br>  
     
  <section class="section-35 section-md-50">
    <div class="container">
      <h4 style="text-align: center;">¿Te gusta comunicar tus ideas? ¿Te interesa crear las campañas de marketing más innovadoras? ¿Quieres marcar tendencia en redes sociales y otras plataformas? ¿Buscas revolucionar el mundo a través de tus ideas?</h4>
      <br>
     <p class="inset-lg-left-40 inset-xl-left-70 text-secondary">Todo esto lo puedes lograr estudiando Diseño y Comunicación Visual, en el Instituto Sanmiguelense tendrás el mejor equipo de cómputo y los softwares especializados para poder adquirir los conocimientos y la práctica que te ayudarán a plasmar tus sueños e ideas y hacerlas realidad. </p>
    
     <br>
      <div class="row row-30 flex-row-md-reverse justify-content-lg-between">
        <div class="col-md-6">
          <figure><img src="../../../assets/edicion/general/8.png" alt="" width="570" height="386"/>
          </figure>
        </div>
        <div class="col-md-6">
          <div class="inset-lg-right-40 inset-xl-right-85 text-secondary">
          <p>Aprenderás las bases del diseño y las llevarás al mundo digital, dibujo, animación, fotografía, guiados para poder comunicar mensajes poderosos y creativos a través de todos los medios posibles. Aprenderás a utilizar las últimas herramientas de las plataformas digitales para llegar a tu público.</p>
  <p>Estudia una de las carreras más demandadas hoy y con uno de los horizontes más prometedor en el futuro. Obtén las herramientas que te ayudarán a despegar en el mundo laboral.

    Como Diseñador y comunicador visual podrás desenvolverte en industrias como:
  Diseño y Publicidad, empresas del área web y multimedia, editoriales y medios de comunicación, compañías productoras de envases y empaquess, museos, galerías de arte, centros culturales</p>  

     </div>
        </div>
      </div>
    </div>
  </section>
  <h4 style="text-align: center;">Vinculación Empresarial</h4>
  <img class="d-block w-100" src="../../../assets/edicion/varioslogos/4.png" >
  <section class="section-40 section-md-bottom-100 section-xl-bottom-165">
    <div class="container">
      <h4>Algunas de las áreas donde nuestros egresados se encuentran trabajando son:</h4>
      <div class="row row-30 justify-content-lg-between">
        <div class="col-md-6">
          <figure><img src="../../assets/edicion//general/4.png" alt="" width="570" height="386"/>
          </figure>
        </div>
        <div class="col-md-6">
          <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
            <p>Consultores externos.            </p>
            <p>Desarrollo de Campañas</p>
            <p>Fotografía.</p>
            <p>Diseño Gráfico</p>
            <p>Diseño Web.</p>
            <p>Marketing Digital.</p>
            <p>
              Community Manager.
            </p>
            <p>Entre otros.</p>
        
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="container">

    <div class="row row-30 justify-content-lg-between">
      <div class="col-md-4">
       
        <button class="btn btn-info" (click)="open(mymodal1)"><img src="../../assets/edicion/btn1.png" alt=""></button>
   
        <ng-template #mymodal1 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
         
              <img src="../../assets/edicion/soydragon/14.png" alt="" width="100%" height="auto">
         
          </div>
        
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>
      <div class="col-md-4">
        <button class="btn btn-info" (click)="open(mymodal2)"><img src="../../assets/edicion/btn2.png" alt=""></button>
   
        <ng-template #mymodal2 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
     
              <img src="../../assets/edicion/soydragon/15.png" alt="">
           
          </div>
          
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>

      <div class="col-md-4">
        <button class="btn btn-info" (click)="open(mymodal3)"><img src="../../assets/edicion/btn3.png" alt=""></button>
   
        <ng-template #mymodal3 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
            <img src="../../assets/edicion/reticuladis.png" alt="" width="3000" height="auto"> 
           
      
          </div>
          
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <br>