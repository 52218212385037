
        <img class="d-block w-100" src="../../../assets/edicion/app.png" >
     
     
        <br>
     <br>  


    <div class="embed-responsive embed-responsive-21by9"> 
      <youtube-player 
      videoId="0a80a5i2n3o" 
      [height]="500"
      [width]="1000" >
    </youtube-player>
</div>
<br>
<img class="d-block w-100" src="../../../assets/edicion/soydragon/1.png" >
<img class="d-block w-100" src="../../../assets/edicion/soydragon/2.png" >

     