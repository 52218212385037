import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import 'hammerjs';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})

export class InicioComponent implements OnInit {
  not: any;
  numero: any;
  imagen: any;
  contenido: any;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  clave = window.location.protocol;
  constructor(private http: Http) { }

  noticias() {
    this.http
      .get(this.clave + '//itses.edu.mx/webservices-sii/mobile/api_soydragon.php?Funcion=Noticias')
      .subscribe(data => {
        console.log(data.json());
        this.not = data.json();
      });
  }
  new(id, portada, content) {
    this.numero = id;
    this.imagen = portada;
    this.contenido = content;
  }
  ngOnInit(): void {
    this.galleryOptions = [
      { 
          width: '100%',
          height: '650px',
          thumbnailsColumns: 4,
          imageAnimation: NgxGalleryAnimation.Slide,
          
      },
      // max-width 800
      {
          breakpoint: 800,
          width: '100%',
          height: '600px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
      },
      // max-width 400
      {
          breakpoint: 400,
          preview: false
      }
  ];

  this.galleryImages = [
      {
          small: 'assets/edicion/gal/9.png',
          medium: 'assets/edicion/gal/9.png',
          big: 'assets/edicion/gal/9.png'
      },
      {
          small: 'assets/edicion/gal/10.png',
          medium: 'assets/edicion/gal/10.png',
          big: 'assets/edicion/gal/10.png'
      },
      {
          small: 'assets/edicion/gal/5.png',
          medium: 'assets/edicion/gal/5.png',
          big: 'assets/edicion/gal/5.png'
      },
      {
        small: 'assets/edicion/gal/7.png',
        medium: 'assets/edicion/gal/7.png',
        big: 'assets/edicion/gal/7.png'
    },
    {
      small: 'assets/edicion/gal/3.png',
      medium: 'assets/edicion/gal/3.png',
      big: 'assets/edicion/gal/3.png'
  },
  {
    small: 'assets/edicion/gal/2.png',
    medium: 'assets/edicion/gal/2.png',
    big: 'assets/edicion/gal/2.png'
},
{
  small: 'assets/edicion/gal/11.png',
  medium: 'assets/edicion/gal/11.png',
  big: 'assets/edicion/gal/11.png'
}
  ];


    this.noticias();
  }

}
