<footer class="page-foot bg-ebony-clay">
    <div class="section-40 section-md-75">
      <div class="container">
        <div class="row justify-content-sm-center">
          <div class="col-sm-9 col-md-11 col-xl-12">
            <div class="row row-50">
              <div class="col-md-6 col-lg-10 col-xl-3">
                <div class="inset-xl-right-20" style="max-width: 510px;"><a class="brand" href="index.html"><img src="assets/logo2.png" alt="" width="143" height="28"/></a>
                  <p>
                    En el Instituto Sanmiguelense creemos firmemente que el desarrollo de una sociedad reside en una educación integral, incluyente y accesible. 
                  </p> 
                </div>
                <!-- <button type="button" class="btn btn-success" mdbBtn color="" routerLink="/admisiones" mdbWavesEffect style="">
                  <span class="texto3">Admisiones</span>
                </button> -->
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
               <p class="h7">Enterate de lo más nuevo
                de la comunidad Dragón</p>
                  
                          <div class="row" >
                            <div class="col-md-6 col-lg-6 col-xs-12 " style="width: 50%; height: auto;">
                              <img src="../../assets/edicion/dragon.png" alt="" class="img-fluid" style="width: 90%; height: auto">

                            </div>
                            <div class="col-md-6 col-lg-6 col-xs-12 " style="width: 50%;height: auto;">
                              <a href="https://apps.apple.com/mx/app/soy-drag%C3%B3n/id1267935538" target="_blank">
                                <img src="../../assets/appstore.svg" alt="" class="img-fluid" style="width: 100%; height: auto;">
                              </a>
                              <br>
                              <br>
                              <a href="https://play.google.com/store/apps/details?id=com.pts.soydragon" target="_blank">
                              <img src="../../assets/playstore.svg" alt="" style="width: 100%; height: auto;">
                            </a>
                            </div>
                            </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <p class="h7">Oferta Educativa</p>
                <div class="row" style="max-width: 270px;">
                  <div class="col-6">
                    <ul class="list-marked-variant-2">
                
                      <li><a routerLink="mecanica">Ingeniería Mecánica</a></li>
                      <li><a routerLink="sistemas">Ingeniería en Sistemas</a></li>
     
                    </ul>
                  </div>
                  <div class="col-6">
                    <ul class="list-marked-variant-2">
                      <li><a routerLink="gestion">Gestión Empresarial</a></li>
                      <li><a routerLink="diseno">Diseño y comunicación visual</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <p class="h7">Contacto</p>
                <address class="contact-info text-left">
                  <div class="unit unit-horizontal unit-spacing-md align-items-center">
                    <div class="unit-left"><span class="novi-icon icon icon-xs icon-storm-gray material-icons-phone"></span></div>
                    <div class="unit-body"><a class="link-white" href="tel:#">415 154 8484</a></div>
                  </div>
                  <div class="unit unit-horizontal unit-spacing-md align-items-center">
                    <div class="unit-left"><span class="novi-icon icon icon-xs icon-storm-gray fa fa-envelope-o"></span></div>
                    <div class="unit-body"><a class="link-white" href="mailto:#">info@itses.edu.mx</a></div>
                  </div>
                  <div class="unit unit-horizontal unit-spacing-md">
                    <div class="unit-left"><span class="novi-icon icon icon-xs icon-storm-gray material-icons-place"></span></div>
                    <div class="unit-body"><a class="link-white d-inline" href="#">Escuadrón 201 no.10<br>Colonia Palmita de Landeta</a></div>
                  </div>
                  <div class="unit unit-horizontal unit-spacing-md">
                    <div class="unit-left"><span class="novi-icon icon icon-xs icon-storm-gray fa fa-whatsapp"></span></div>
                    <div class="unit-body"><a class="link-white d-inline" href="https://api.whatsapp.com/send/?phone=524151569975&text&app_absent=0"> 415 156 9965</a></div>
                  </div>
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <hr>
    </div>
    <div class="section-35">
      <div class="container text-center">
        <div class="row row-15 flex-md-row-reverse justify-content-md-between align-items-md-center">
          <div class="col-md-6 text-md-right">
            <p class="rights text-white"><span class="copyright-year"></span><span>&nbsp;&#169;&nbsp;</span><span>&nbsp; 2021</span>&nbsp;&nbsp;Instituto Sanmiguelense<a href=""></a></p>
          </div>
          <div class="col-md-6 text-md-left">
            <div class="group-sm group-middle">
              <p class="font-italic text-white">Siguenos:</p>
              <ul class="list-inline list-inline-reset">
                <li><a class="novi-icon icon icon-circle icon-bright-gray-filled icon-xxs-smaller fa fa-facebook" href="https://www.facebook.com/InstitutoSanmiguelense"></a></li>
                <li><a class="novi-icon icon icon-circle icon-bright-gray-filled icon-xxs-smaller fa fa-twitter" href="https://twitter.com/ISanmiguelense"></a></li>
                <li><a class="novi-icon icon icon-circle icon-bright-gray-filled icon-xxs-smaller fa fa-youtube" href="https://www.youtube.com/channel/UC_X7ZkgUPJ3KUVIMCHirJ5w"></a></li>
                <li><a class="novi-icon icon icon-circle icon-bright-gray-filled icon-xxs-smaller fa fa-whatsapp" href="https://api.whatsapp.com/send/?phone=524151569975&text&app_absent=0"></a></li>
                <li><a class="novi-icon icon icon-circle icon-bright-gray-filled icon-xxs-smaller fa fa-instagram" href="https://www.instagram.com/instituto_sanmiguelense/"></a></li>
              
            </ul>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  </footer>