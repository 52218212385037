<div class="container " style="margin-top:50px;margin-bottom: 50px">
    <div>
        <div class="card card-body">
            <form [formGroup]="Formulario" (ngSubmit)="onSubmit()" role="form">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="inputPassword4">Nombre(s) <span style="color:red">*</span></label>
                        <input type="text" formControlName="nombre" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" placeholder="Nombre(s)">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="inputPassword4">Apellido paterno <span style="color:red">*</span></label>
                        <input type="text" formControlName="apell_p" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.apell_p.errors }" placeholder="Apellido paterno">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="inputPassword4">Apellido materno</label>
                        <input type="text" formControlName="apell_m" class="form-control" placeholder="Apellido materno">
                      </div>
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Correo electrónico <span style="color:red">*</span></label>
                    <input type="email" formControlName="correo" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.correo.errors }" placeholder="Correo electrónico">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Telefono (WhatsApp) <span style="color:red">*</span></label>
                    <input type="number" formControlName="telefono" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }" placeholder="Telefono (WhatsApp)">
                  </div>
                  
                  <div class="form-group col-md-6">
                    <label for="inputState">Interes <span style="color:red">*</span></label>
                    <select id="inputState"  formControlName="tbl_nivel" [ngClass]="{ 'is-invalid': submitted && f.tbl_nivel.errors }" class="form-control">
                      <option selected disabled>Selecciona un interes</option>
                      <option *ngFor="let Est of indexNivelAcademico" [value]="Est.tbl_nivel_id">{{Est.descripcion}}</option>
                    </select>
                  </div>
                </div>
               
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="inputState">Estado de procedencia <span style="color:red">*</span></label>
                    <select id="inputState" (change)="ObtenerEscuelas($event.target.value)" formControlName="estado" [ngClass]="{ 'is-invalid': submitted && f.estado.errors }" class="form-control">
                      <option selected disabled>Selecciona un estado</option>
                      <option *ngFor="let Est of IndexEstados" [value]="Est.id" >{{Est.descripcion}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputState">Escuela de procedencia <span style="color:red">*</span></label>
                    <select id="inputState" class="form-control" formControlName="escuela" [ngClass]="{ 'is-invalid': submitted && f.escuela.errors }">
                      <option selected disabled>Selecciona una escuela</option>
                      <option *ngFor="let Esc of IndexEscuela" [value]="Esc.id">{{Esc.nombre}}</option>
                      <option value="103">Otro</option>
                    </select>
                  </div>
                  </div>
                  <div class="d-flex justify-content-center"> <button type="submit" class="btn btn-success center">Regístrate</button></div>
              </form>
        </div>
      </div>
</div>