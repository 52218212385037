import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageHttp'
})
export class ImageHttpPipe implements PipeTransform {

  transform(value: any): any {
    return value.replace('http:', window.location.protocol);
  }

}
