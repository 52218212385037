
        <img class="d-block w-100" src="../../../assets/edicion/becas.png" >
        <h4 class="wow fadeInUp t" style="text-align: center;">Más del 50% de nuestros alumnos obtienen una beca en algún momento de sus estudios. </h4>

     <br>
     <p class="inset-lg-left-40 inset-xl-left-70 text-secondary">Desde su creación en 2004, el Instituto Sanmiguelense ha tomado la responsabilidad de apoyar a los estudiantes destacados con recursos limitados para que continúen sus estudios en una institución de calidad donde pueden acceder a un buen número de herramientas e instalaciones que apoyan su desarrollo integral.</p>
     <br> 

    <div class="embed-responsive embed-responsive-21by9" > 
        <youtube-player 
        videoId="XXU016vIlQs" 
        [height]="500" 
          [width]="1000" 
     >
      </youtube-player>
  </div>

<br>
<br>

<h3 class="wow fadeInUp t" style="text-align: center;" >En 3 sencillos pasos puedes obtener tu beca</h3>
  <img class="center d-block w-60" src="../../../assets/edicion/becas/cuadro.png" >

    
    <br>
    <div class="container" style="text-align: center;">

      <div class="row row-60 " >
        <div class="col-md-2"></div>
    
    <div class="col-md-4" style="text-align: right;">
      <button class="btn btn-info"><a href="assets/archivos/reglamento2021.pdf" target="_blank"><img src="../../assets/edicion/btn5.png" alt=""></a></button>
   
      </div>
      <div class="col-md-4"  style="text-align:left;">
        <button class="btn btn-info"><a href="assets/archivos/formatobeca2021.pdf" target="_blank"><img src="../../assets/edicion/btn4.png" alt=""></a></button>
   
      </div>

      <div class="col-md-2"></div>
    

    </div>


    </div>
    <br>
    <h3 class="wow fadeInUp t" style="text-align: center;" >Coordinador de Becas</h3>
    <h4 class="wow fadeInUp t" style="text-align: center;">Lic. Verónica Vázquez</h4>
    
    <br>
    <br>
    <div class="container">
      <div class="row row-60 " >
    <div class="col-md-6" style="text-align: right;">
      <h4 class="wow fadeInUp t" style="text-align: center;" >Correo electrónico</h4>
      <h5 class="wow fadeInUp t" style="text-align: center;"><span class="fa fa-envelope-o"></span> veronica.vazquez@itses.edu.mx</h5>
      
      
      </div>
      
      <div class="col-md-6" style="text-align: left;">
        <h4 class="wow fadeInUp t" style="text-align: center;" >Teléfono</h4>
        <h5 class="wow fadeInUp t" style="text-align: center;"><span class="material-icons-phone"></span> (415) 154 8484 EXT.155</h5>
        
        
        </div>
      </div>
    </div>
 
    <br>
  
        <br>