<img class="d-block w-100" src="../../../assets/edicion/vinculacion.png">
<br>
<div class="container-fluid esp1 mac">
  <p class="wow fadeInUp t">Los mejores alumnos en las mejores empresas</p>
</div>
<div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
  <!--<ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators2" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="3"></li>∫
    <li data-target="#carouselExampleIndicators2" data-slide-to="4"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="5"></li>
  </ol>-->
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/3.png" alt="" width="246" height="300" />
              <br>

            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
              <figure>
                <h5> El nivel académico de los alumnos del Sanmiguelense se encuentra encima del de otras universidades
                  en Querétaro y Guanajuato. Su nivel de inglés es excelente y un plus para su ingreso a nuestra
                  empresa, lo que resulta una ventaja competitiva frente a otros candidatos. </h5>
                <div class="quote-footer">
                  <cite>- Jessica Estañol. HR Specialist Huawei Technologies de México. GSC Querétaro.</cite>
                  <br>
                </div>
              </figure>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/4.png" alt="" width="246" height="300" />
              <br>

            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
              <figure>
                <h5> Los alumnos del Sanmiguelense de Diseño y Comunicación, así como de Gestión Empresarial, han
                  cumplido con las expectativas de nuestra empresa. Su preparación académica es excelente para lo que
                  demanda el mercado laboral. </h5>
                <div class="quote-footer">
                  <cite>- Joana Rodríguez. Administradora Kalôn & Agathos Bajío.</cite>
                  <br>
                </div>
              </figure>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/2.png" alt="" width="246" height="300" />
              <br>

            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
              <figure>
                <h5> Los alumnos del Sanmiguelense que han participado en nuestro evento Aggies Invent lo han hecho de
                  manera sobresaliente y se encuentran al nivel de cualquier universidad del mundo. El enfoque
                  tecnológico y de innovación es asombroso.
                </h5>
                <div class="quote-footer">
                  <cite>- Pablo Marvin. Director del Study Abroad Program de Hacienda Santa Clara y representante de la
                    universidad de Texas A&M en México.</cite>
                  <br>
                </div>
              </figure>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/6.png" alt="" width="246" height="300" />
              <br>

            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
              <figure>
                <h5> Dada la formación que reciben los estudiantes en el ITSES en áreas de ingeniería mecánica con un
                  componente importante de simulación computacional, son candidatos idóneos para la maestría así como
                  para algún tipo de proyectos que aquí se desarrollan. </h5>
                <div class="quote-footer">
                  <cite>- Carlos A. Escalante V.
                    Investigador CONACYT-CIDESI (CONACYT Research Fellow)</cite>
                  <br>
                </div>
              </figure>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/5.png" alt="" width="246" height="300" />
              <br>

            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
              <figure>
                <h5> El Instituto Sanmiguelense es una de las principales universidades que nos ayuda a reunir talentos
                  para cubrir las necesidades de Valeo, en especial para nuestro Centro Técnico en México (MTC) debido a
                  su alta especialización. </h5>
                <div class="quote-footer">
                  <cite>- Vanessa Fonseca, Country Talent Acquisition Manager - Mèxico</cite>
                  <br>
                </div>
              </figure>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">

        <div class="row row-30 justify-content-lg-between">
          <div class="col-md-3">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary" style="text-align: center;">
              <br>
              <img src="../../assets/edicion/testimonios/1.png" alt="" width="246" height="300" />
              <br>

            </div>
          </div>
          <div class="col-md-9 ">
            <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">
              <figure>
                <h5> Estuve a punto de ingresar a otra institución, sin embargo, cuando consulté el plan de estudio me
                  di cuenta que lo que iba a ver de diseño y simulación ya lo había aprendido en la Prepa Sanmiguelense,
                  y en el Instituto Sanmiguelense cuentan con un programa más avanzado y profesores más capacitados. En
                  resumen, son los expertos en diseño y simulación por computadora.
                </h5>
                <div class="quote-footer">
                  <cite>- Mario Kilian. Alumno de Ingeniería en Sistemas y Desarrollo de Software.</cite>
                  <br>
                </div>
              </figure>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev icon" href="#carouselExampleIndicators2" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
<br>



<div class="container">
  <h4 style="text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif;">Más del 80% de nuestros alumnos
    obtienen una oferta laboral antes de egresar. </h4>

  <div class="row row-40 align-items-sm-end">

    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/56.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-4 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/30.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/31.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/32.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/33.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/35.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/36.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/37.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/38.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/39.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/40.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/41.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/42.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/43.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/44.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/46.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/47.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/48.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/49.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/50.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/51.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/52.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/53.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/54.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/55.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>

    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/57.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/58.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/59.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/60.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/61.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/62.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/63.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/64.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/65.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
  </div>
</div>






<div class="container">
  <h4 style="text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif;">Aliados estratégicos. </h4>
  <div class="row row-40 align-items-sm-end">

    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/1.png" alt="" width="246" height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/2.png" alt="" width="246" height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/3.png" alt="" width="246" height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/4.png" alt="" width="246" height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/5.png" alt="" width="246" height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/6.png" alt="" width="246" height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/7.png" alt="" width="246" height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/8.png" alt="" width="246" height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/9.png" alt="" width="246" height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/10.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/11.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/12.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
  </div>
</div>





<div class="container">
  <h4 style="text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif;">Más del 95% de nuestros alumnos
    ingresan a la universidad de su elección. </h4>
  <div class="row row-40 align-items-sm-end">

    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/13.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/14.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/15.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/16.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/17.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/18.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/19.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/20.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/21.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/22.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/23.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/24.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/25.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/26.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/27.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-2 col-md-2 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/28.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
    <div class="col-sm-2 col-md-2 col-lg-2">
      <div class="thumbnail-variant-2-wrap">
        <div>
          <figure class="thumbnail-image"><img src="../../assets/edicion/logos/29.png" alt="" width="246"
              height="300" />
          </figure>


        </div>
      </div>
    </div>
  </div>
</div>