import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
@Component({
  selector: 'app-orgullo-dragon',
  templateUrl: './orgullo-dragon.component.html',
  styleUrls: ['./orgullo-dragon.component.css']
})
export class OrgulloDragonComponent implements OnInit {
  orgullodragon: any;



  constructor(private http: Http) { }

  dragones() {
    this.http
      .get('http://itses.edu.mx/ws-sii-v2/controllers/mobile/_api.php?Funcion=BuscarOrgullo')
      .subscribe(data => {
         //console.log(data.json());
        this.orgullodragon = data.json();
      });
  }
 

  ngOnInit(): void {
    this.dragones();

  }
 
}
