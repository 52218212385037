import { Component, OnInit } from '@angular/core';
import 'hammerjs';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';
@Component({
  selector: 'app-instalaciones',
  templateUrl: './instalaciones.component.html',
  styleUrls: ['./instalaciones.component.css']
})
export class InstalacionesComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      { 
          width: '100%',
          height: '650px',
          thumbnailsColumns: 4,
          imageAnimation: NgxGalleryAnimation.Slide,
          
      },
      // max-width 800
      {
          breakpoint: 800,
          width: '100%',
          height: '600px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
      },
      // max-width 400
      {
          breakpoint: 400,
          preview: false
      }
  ];

  this.galleryImages = [
      {
          small: 'assets/edicion/gal/4.png',
          medium: 'assets/edicion/gal/4.png',
          big: 'assets/edicion/gal/4.png'
      },
      {
          small: 'assets/edicion/gal/9.png',
          medium: 'assets/edicion/gal/9.png',
          big: 'assets/edicion/gal/9.png'
      },
      {
          small: 'assets/edicion/gal/7.png',
          medium: 'assets/edicion/gal/7.png',
          big: 'assets/edicion/gal/7.png'
      },
      {
        small: 'assets/edicion/gal/8.png',
        medium: 'assets/edicion/gal/8.png',
        big: 'assets/edicion/gal/8.png'
    },
    {
      small: 'assets/edicion/gal/6.png',
      medium: 'assets/edicion/gal/6.png',
      big: 'assets/edicion/gal/6.png'
  },
  {
    small: 'assets/edicion/gal/1.png',
    medium: 'assets/edicion/gal/1.png',
    big: 'assets/edicion/gal/1.png'
}
  ];

  }

}
