
        <img class="d-block w-100" src="../../../assets/edicion/gestion.png" >
     
        <br>
        <div class="embed-responsive embed-responsive-21by9"> 
          <youtube-player 
          videoId="l00eb_bPOPk" 
          [height]="500" 
          [width]="1000" >
        </youtube-player>
    </div>
<br>  
     
  <section class="section-35 section-md-50">
    <div class="container">
      <h4 style="text-align: center;">¿Estás interesado en la planificación, organización y control
        de recursos empresariales? ¿Quieres hacer negocios e
        interactuar con personas de diferentes culturas? ¿Te gustan
        las áreas funcionales de una empresa como finanzas,
        producción, recursos humanos y mercadotecnia?</h4>
      <br>
     <p class="inset-lg-left-40 inset-xl-left-70 text-secondary">Gestión Empresarial es una carrera multidisciplinaria que
      forma alumnos capaces de desarrollarse en puestos gerenciales
      y/o en cualquier departamento administrativo, el Instituto
      
      Sanmiguelense desarrolla alumnos con la capacidad de crear
      estrategias para llevar a las empresas al éxito. </p>
    
     <br>
      <div class="row row-30 flex-row-md-reverse justify-content-lg-between">
        <div class="col-md-6">
          <figure><img src="../../../assets/edicion/general/7.png" alt="" width="570" height="386"/>
          </figure>
        </div>
        <div class="col-md-6">
          <div class="inset-lg-right-40 inset-xl-right-85 text-secondary">
          
<p>En el Instituto Sanmiguelense también te darán todas las
  herramientas para que puedas emprender y crear tu propia
  empresa, a manejar y crear equipos de trabajo, a crear
  productos y estrategias innovadoras y a utilizar los medios
  digitales para llegar a tu público a gran escala.
  Gracias a los diferentes eventos, vinculaciones y proyectos
  del Instituto Sanmiguelense tendrás oportunidad de poner en
  práctica tus conocimientos, lo que te ayudará a abrir las
  puertas del mundo laboral con gran facilidad.</p>
   <p>Más del 80% de nuestros estudiantes recibe una propuesta de
    trabajo antes de terminar la escuela.
    Como Gestor Empresarial podrás desenvolverte en cualquier
    tipo de industria, ya sea trabajando para alguna empresa o
    creando una propia</p>
     </div>
        </div>
      </div>
    </div>
  </section>
  <h4 style="text-align: center;">Vinculación Empresarial</h4>
  <img class="d-block w-100" src="../../../assets/edicion/varioslogos/3.png" >
  <section class="section-40 section-md-bottom-100 section-xl-bottom-165">
    <div class="container">
      <h4>Algunas de las áreas donde nuestros egresados se encuentran trabajando son:</h4>
      <div class="row row-30 justify-content-lg-between">
        <div class="col-md-6">
          <figure><img src="../../assets/edicion/general/12.png" alt="" width="570" height="386"/>
          </figure>
        </div>
        <div class="col-md-6">
          <div class="inset-lg-left-40 inset-xl-left-70 text-secondary">

           <p>Recursos Humanos.</p>
           <p>Administación.</p>
           <p>Finanzas.</p>
           <p>Desarrollo de Proyectos.</p>
           <p>Customer Service.</p>
           <p>Planeación y análisis de datos.</p>
            <p>Entre otros.</p>
          </div>
        </div>
      </div>
    </div>
  </section>


  <div class="container">

    <div class="row row-30 justify-content-lg-between">
      <div class="col-md-4">
       
        <button class="btn btn-info" (click)="open(mymodal1)"><img src="../../assets/edicion/btn1.png" alt=""></button>
   
        <ng-template #mymodal1 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
            <img class="d-block w-100" src="../../assets/edicion/soydragon/11.png" alt="" width="100%" height="auto">

          </div>
         
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>
      <div class="col-md-4">
        <button class="btn btn-info" (click)="open(mymodal2)"><img src="../../assets/edicion/btn2.png" alt=""></button>
   
        <ng-template #mymodal2 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
            <img class="d-block w-100" src="../../assets/edicion/soydragon/12.png" alt="">
          </div>
        
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>
      <div class="col-md-4">
        <button class="btn btn-info" (click)="open(mymodal3)"><img src="../../assets/edicion/btn3.png" alt=""></button>
   
        <ng-template #mymodal3 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
            <img src="../../assets/edicion/reticulages.png" alt="" width="3000" height="auto"> 
          </div>
        
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <br>